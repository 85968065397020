<template>
  <div class="pipelines-page">

    <b-card id="card-pipelines">
      <div class="container-card-header">
        <span class="title">Pipelines</span>

        <div class="container-buttons">
          <b-button
            class="btn-new-pipeline"
            :to="{ name: 'new-pipeline' }"
          >
            <span>
              {{ $t('IntegrationPage.NewPipeline') }}
            </span>
          </b-button>
        </div>
      </div>

      <div class="container-table">
        <table-default
          :rows="pipelinesList"
          :fields="fields"
          :stickyHeader="false"
          :striped="true"
          :sizeSearch="12"
          id="table-pipelines"
          @update-displayed-rows="(v) => (displayedRows = v)"
        >
          <template v-slot:cell(action)="data">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                class="d-action-pipelines"
                :boundary="displayedRows > 3 ? 'scrollParent' : 'window'"
                dropright
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  :to="{
                    name: 'new-pipeline',
                    params: { action: 'new-activity', id: data.item.id }
                  }"
                >
                  <span class="icon-action-pipeline">
                    <AddIcon />
                  </span>
                  <span class="text-action-pipeline">
                    {{ $t('IntegrationPage.NewActivity') }}
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="executePipeline(data.item.id)"
                  :disabled="data.item.execution_type == 'Auto Resolve Integration Runtime'"
                >
                  <span class="icon-action-pipeline">
                    <PlayIcon />
                  </span>
                  <span class="text-action-pipeline">
                    {{ $t('IntegrationPage.ExecuteNow') }}
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  :to="{
                    name: 'new-pipeline',
                    params: { action: 'to-schedule', id: data.item.id }
                  }"
                  :disabled="data.item.execution_type == 'Auto Resolve Integration Runtime'"
                >
                  <span class="icon-action-pipeline">
                    <CalendarIcon />
                  </span>
                  <span class="text-action-pipeline">
                    {{ $t('IntegrationPage.ToSchedule') }}
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="clearSchedules(data.item.id)"
                  :disabled="data.item.execution_type == 'Auto Resolve Integration Runtime'"
                >
                  <span class="icon-action-pipeline">
                    <PencilIcon />
                  </span>
                  <span class="text-action-pipeline">
                    {{ $t('IntegrationPage.ClearScheduling') }}
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  :to="{ name: 'new-pipeline', params: { action: 'edit', id: data.item.id } }"
                >
                  <span class="icon-action-pipeline">
                    <EditIcon />
                  </span>
                  <span class="text-action-pipeline">
                    {{ $t('IntegrationPage.Edit') }}
                  </span>
                </b-dropdown-item>
                <b-dropdown-item @click="deletePipeline(data.item.id)">
                  <span class="icon-action-pipeline">
                    <DeleteIcon />
                  </span>
                  <span class="text-action-pipeline">
                    {{ $t('IntegrationPage.Delete2') }}
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </template>

          <template v-slot:cell(name)="data">
            <div class="column-item-pipeline">
              <div
                class="add-ellipsis-p"
                :id="`tooltip-pipeline-name-${data.index}`"
              >
                <span>
                  {{ data.item.name }}
                </span>
              </div>
            </div>
            <b-tooltip
              :target="`tooltip-pipeline-name-${data.index}`"
              triggers="hover blur"
              positioning="top"
              placement="top"
              boundary-padding="0"
              custom-class="tooltip-pipelines-table"
            >
              <div class="info-tooltip-pipelines-table">
                {{ data.item.name }}
              </div>
            </b-tooltip>
          </template>

          <template v-slot:cell(event)="data">
            <div class="add-ellipsis-p">
              <span :id="`tooltip-event-${data.index}`">
                {{ data.item.event ? data.item.event : '-' }}
              </span>
            </div>
            <b-tooltip
              :target="`tooltip-event-${data.index}`"
              triggers="hover blur"
              positioning="top"
              placement="top"
              boundary-padding="0"
              custom-class="tooltip-pipelines-table"
            >
              <div class="info-tooltip-pipelines-table">
                {{ data.item.event ? data.item.event : '-' }}
              </div>
            </b-tooltip>
          </template>

          <template v-slot:cell(next_execution)="data">
            <span>
              {{ data.item.next_execution ? formatDateHour(data.item.next_execution) : '-' }}
            </span>
          </template>
        </table-default>
      </div>
    </b-card>
  </div>
</template>

<script lang="js">
  import TableDefault from '@/@core/components/table-default/TableDefault.vue';
  import moment from 'moment';
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
  import DeleteIcon from '@/assets/images/icons/trash-icon-2.svg';
  import EditIcon from '@/assets/images/pages/edit-icon-grey.svg';
  import PencilIcon from '@/assets/images/icons/clear-icon-2.svg';
  import AddIcon from '@/assets/images/icons/add-circle-icon.svg';
  import PlayIcon from '@/assets/images/icons/play-circle-icon.svg';
  import CalendarIcon from '@/assets/images/icons/calendar-icon-2.svg';
  import { VueGoodTable } from 'vue-good-table';
  import Ripple from 'vue-ripple-directive';
  import router from '@/router';
  import { mapMutations } from 'vuex';
  import {
    BRow,
    BTooltip,
    BPagination,
    BFormGroup,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BCard,
    VBToggle,
    BButton
  } from 'bootstrap-vue';

  export default {
    components: {
      BRow,
      VueGoodTable,
      BTooltip,
      BPagination,
      BFormGroup,
      BFormInput,
      BDropdown,
      BDropdownItem,
      BCard,
      BButton,
      TableDefault,
      moment,
      EditIcon,
      DeleteIcon,
      PencilIcon,
      CalendarIcon,
      PlayIcon,
      AddIcon,
      ToastificationContent
    },
    directives: {
      'b-toggle': VBToggle,
      Ripple
    },
    data() {
      return {
        currentSite: JSON.parse(localStorage.getItem('currentSite')),
        pageLength: 10,
        dir: false,
        connectionId: '',
        fields: [
          {
            label: 'IntegrationPage.Actions',
            key: 'action',
            sortable: false
          },
          {
            label: 'PIPELINE',
            key: 'name',
            sortable: true
          },
          {
            label: 'IntegrationPage.PerformedVia',
            key: 'execution_type',
            sortable: true
          },
          {
            label: 'IntegrationPage.Event',
            key: 'event',
            sortable: true
          },
          {
            label: 'IntegrationPage.NextExecution',
            key: 'next_execution',
            sortable: true,
            class: 'col-name-type'
          }
        ],
        pipelinesList: [],
        searchTerm: '',
        refreshing: false,
        testing: false,
        displayedRows: 0
      };
    },
    mounted() {
      this.getPipelines();
    },
    methods: {
      ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY']),
      changePage() {
        router.push({ name: 'new-pipeline' });
      },
      executePipeline(pipelineId) {
        this.$http
          .put(
            `/api/${this.$router.currentRoute.params.prefix}/event_script/pipelines/set_execute_now/${pipelineId}`
          )
          .then((res) => {
            this.showToast(
              this.$t('IntegrationPage.success'),
              'SmileIcon',
              this.$t('IntegrationPage.MessageExecutePipeline'),
              'success'
            );
            this.getPipelines();
          })
          .catch((e) =>
            this.showToast(
              this.$t('IntegrationPage.Attention'),
              'SmileIcon',
              this.$t('IntegrationPage.MessagePipelineAlreadyRunning'),
              'alert'
            )
          );
      },
      clearSchedules(pipelineId) {
        this.$http
          .delete(
            `/api/${this.$router.currentRoute.params.prefix}/event_script/pipelines/scheduling/${pipelineId}`
          )
          .then((res) => {
            this.getPipelines();
            this.showToast(
              this.$t('IntegrationPage.success'),
              'SmileIcon',
              this.$t('IntegrationPage.MessageCleatSchedules'),
              'success'
            );
          });
      },
      deletePipeline(pipelineId) {
        this.$swal({
          title: this.$t('IntegrationPage.attention'),
          text: this.$t('IntegrationPage.DeleteAlertPipeline'),
          showCancelButton: true,
          showCloseButton: true,
          cancelButtonText: this.$t('MySites.cancel'),
          confirmButtonText: this.$t('IntegrationPage.Delete2'),
          customClass: {
            container: 'swal-scheduling',
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false
        }).then((result) => {
          if (result.value) {
            this.UPDATE_FLAG_SHOW_OVERLAY(true);
            this.$http
              .delete(
                `/api/${this.$router.currentRoute.params.prefix}/event_script/pipelines/${pipelineId}`
              )
              .then(() => {
                this.showToast(
                  this.$t('IntegrationPage.success'),
                  'SmileIcon',
                  this.$t('IntegrationPage.MessageDeletePipeline'),
                  'success'
                );
                this.getPipelines();
              });
          }
        });
      },
      getPipelines() {
        this.UPDATE_FLAG_SHOW_OVERLAY(true);
        this.$http
          .get(
            `/api/${this.$router.currentRoute.params.prefix}/event_script/pipelines/list?site_guid=${this.currentSite.guid}`
          )
          .then((res) => {
            this.pipelinesList = res.data;
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
          });
      },
      showToast(title, icon, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: text,
            variant
          }
        });
      },
      formatDateHour(date) {
        let lang = this.$cookies.get('userInfo').language;

        if (lang == 'pt-BR') return moment(date).format('DD-MM-YYYY HH:mm:ss');

        if (lang == 'en-US') return moment(date).format('MM-DD-YYYY HH:mm:ss');

        return date;
      }
    }
  };
</script>

<style lang="scss">
  .pipelines-page {
    overflow-x: hidden;
    min-height: calc(100vh - 7rem);
  }
  #card-pipelines {
    .dropdown-menu {
      position: absolute !important;
    }

    .container-card-header {
      flex-direction: row;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        color: #4c4541;
      }

      .container-buttons {
        .btn-new-pipeline {
          display: flex;
          padding: 0 !important;
          background: #974900 !important;
          border-radius: 5px;
          border: none;
          span {
            width: 100%;
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            color: #ffffff;
            letter-spacing: 0.0125em;
            padding: 4px 14px;
          }

          &:hover {
            box-shadow: none;
            background: #9f5714 !important;
          }

          &:active {
            background: #a45f1f !important;
          }
        }
      }
    }

    .container-table {
      #table-default thead th {
        width: 7% !important;
      }

      table {
        .column-item-pipeline {
          display: flex;

          .add-ellipsis-p {
            white-space: nowrap;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .form-control {
          height: 38px !important;
        }

        .icon-action-pipeline {
          svg {
            height: 16px;
            width: 16px;

            path {
              fill: #998f8a;
            }
          }
          margin-right: 9px;
        }

        .text-action-pipeline {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #998f8a;
        }

        td {
          padding: 14.5px 10px !important;
          height: 38px !important;
          letter-spacing: 0;
          border: 1px solid #e0e0e0 !important;
          border-bottom: none !important;
          border-left: none !important;
          border-right: none !important;
        }

        thead th {
          padding: 10.5px 9px !important;
        }

        th {
          padding: 11px 9px !important;
        }

        th:nth-child(1) {
          padding: 10.5px 7.65px !important;
        }

        td:nth-child(1) {
          text-align: center !important;
        }

        th:nth-child(1),
        td:nth-child(1) {
          min-width: 57px !important;
          width: 57px !important;
        }

        th:nth-child(2),
        td:nth-child(2) {
          width: 100%;
          min-width: 422px;
          max-width: 422px;
        }
        th:nth-child(3),
        td:nth-child(3) {
          min-width: 237px;
          max-width: 237px;
        }
        th:nth-child(4),
        td:nth-child(4) {
          min-width: 278px;
          max-width: 278px;
        }
        th:nth-child(5),
        td:nth-child(5) {
          min-width: 161px;
          max-width: 161px;
        }

        .d-action-pipelines {
          button {
            width: 32px;
            height: 28px;
            padding: 0;
            svg circle {
              color: #974900 !important;
            }
          }

          .dropdown-menu.show {
            width: 202px;
            height: 280px;
            padding-top: 6.915px !important;
            padding-bottom: 6.915px !important;
          }

          .dropdown-item {
            padding: 12px 45.84px 12px 21px !important;
            &:hover {
              background-color: #fbeee8;
              svg path {
                fill: #974900 !important;
              }
              span {
                color: #974900 !important;
              }
            }

            &.disabled {
              svg {
                path {
                  fill: #cfc4be !important;
                }
              }

              .text-action-pipeline {
                color: #cfc4be !important;
              }
            }
          }

          button:hover {
            background-color: #ffdbc4 !important;
          }

          svg {
            margin: 0 !important;
          }
        }
      }
    }
  }
  .swal-scheduling {
    z-index: 1001;
    .swal2-modal {
      padding: 0 !important;
      border-radius: 6px;
      width: 710px;
      .swal2-close {
        color: #4c4541 !important;
        font-size: 2em;
      }
      .swal2-header {
        background-color: #d32f2f;
        padding: 20px 16px;
        border-radius: 6px 6px 0px 0px;
        flex-direction: row;
        justify-content: left;

        .swal2-title {
          color: #ffff;
          font-weight: 600;
          font-size: 18px;
          line-height: 26px;
          margin: 0;
        }

        .swal2-close {
          border-radius: 6px;
          width: 34px;
          background-color: #ffff;
          height: 34px;
          top: -5px;
          position: absolute;
          right: -5px;
        }
      }
      .swal2-content {
        padding: 20px 16px;

        .swal2-html-container {
          font-weight: 400;
          font-size: 14px;
          text-align: left;
          line-height: 20px;
          color: #4c4541;
          display: flex !important;
        }
      }
      .swal2-actions {
        padding: 0 16px 20px 16px;
        display: flex;
        justify-content: right;
        .swal2-confirm {
          margin-left: 10px !important;
          order: 2;
          font-weight: 600;
          font-size: 17px;
          line-height: 20px;
          color: #ffff;
          border-color: #974900 !important;
          background-color: #974900 !important;
          border-radius: 5px;

          &:hover {
            box-shadow: none !important;
          }
        }
        .swal2-cancel {
          order: 1;
          font-weight: 600;
          font-size: 17px;
          line-height: 20px;
          color: #974900 !important;
          border-radius: 5px;
          border-color: transparent !important;

          &:hover {
            background-color: #ffede1 !important;
          }
        }
      }
    }
  }

  .tooltip-pipelines-table {
    //não adicione padding aqui e só estilize as classes especificas se possível
    margin: 0;
    .tooltip-inner {
      padding: 0;
      max-width: 100%;
      min-width: 1%;
      border-radius: 6px;
      overflow: hidden;

      .info-tooltip-pipelines-table {
        max-width: 371px;
        padding: 8px;
        text-align: left;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .arrow {
      &::before {
        border-top-color: #323232;
        border-bottom-color: #323232;
      }
    }
  }

  @media (max-width: 480px) {
    #card-pipelines {
      .container-card-header {
        flex-direction: column;
        .title {
          align-self: flex-start;
        }

        .container-buttons {
          width: 100%;
          margin-top: 16px;
          .btn-new-pipeline {
            width: 100%;
          }
        }
      }
    }

    .info-tooltip-pipelines-table {
      max-width: 250px !important;
    }
  }

  @media (max-width: 768px) {
    .info-tooltip-pipelines-table {
      max-width: 180px !important;
    }
  }
</style>
